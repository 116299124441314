<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/inventories"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                    <h1>&nbsp;Detalle de Entrada de inventario #{{ id }}</h1>
                </div>
                <vs-switch
                    v-if="$ability.hasScope('inventory:Update') && id"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>
        <div v-if="id" id="inventory-features">
            <div class="row mt-3 mb-5">
                <div class="col-12 d-flex justify-content-between mb-3">
                    <div class="d-flex flex-column">
                        <router-link
                            :to="`/app/inventories/${itemComponent.merchantType}/${itemComponent.merchantId}/detail/${itemComponent.referenceId}`"
                            class="btn-a p-0"
                        >
                            <span class="h4 text-center font-weight-normal">
                                {{ itemComponent.name }}
                            </span>
                        </router-link>

                        <small>
                            Prod
                            <router-link
                                :to="`/app/products/detail/${itemComponent.productId}`"
                                class="btn-a p-0"
                            >
                                {{
                                    itemComponent.product.sku
                                        ? `SKU ${itemComponent.product.sku}`
                                        : `ID ${itemComponent.productId}`
                                }}
                            </router-link>
                            | Ref
                            <router-link
                                :to="`/app/products/detail/${itemComponent.productId}?reference=${itemComponent.referenceId}&active=references`"
                                class="btn-a p-0"
                            >
                                {{
                                    itemComponent.barcode
                                        ? `Cód. ${itemComponent.barcode}`
                                        : `ID ${itemComponent.id}`
                                }}
                            </router-link>
                        </small>
                    </div>
                    <div class="d-flex flex-column">
                        <span>
                            Código de barras :
                            {{ itemComponent.barcode }}
                        </span>
                        <span>
                            SKU :
                            {{ itemComponent.sku }}
                        </span>
                    </div>
                </div>
                <div
                    v-show="costTemp"
                    class="col-6 col-md-4 d-flex flex-column"
                >
                    <span class="text-success"> {{ messageCost }} </span>
                    <span>{{ messageValue }}</span>
                </div>
                <div
                    v-show="costTemp"
                    class="col-6 col-md-4 d-flex flex-column"
                >
                    <span class="text-success"> Precio u. actual </span>
                    <span> {{ itemComponent.priceOffer | money }}</span>
                </div>
                <div
                    v-show="costTemp"
                    class="col-6 col-md-4 d-flex flex-column"
                >
                    <span class="text-success"> Margen bruto </span>
                    <span>{{ revenue | percent }}</span>
                </div>
                <div class="col-6 col-md-4 d-flex flex-column">
                    <span class="text-success">
                        {{ itemComponent.merchantType | merchantInfo("type") }}
                    </span>
                    <span>{{ itemComponent.merchantName }}</span>
                </div>
                <div class="col-6 col-md-4 d-flex flex-column">
                    <span class="text-success">
                        Color | Tamaño/Talla/Valor
                    </span>
                    <span>
                        {{ itemComponent.color }} | {{ itemComponent.size }}
                    </span>
                </div>
                <div class="col-6 col-md-4 d-flex flex-column">
                    <span class="text-success"> Fecha de registro </span>
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                </div>
            </div>

            <div class="col-12 mt-3">
                <vs-input
                    v-model="itemComponent.quantity"
                    border
                    type="number"
                    shadow
                    min="1"
                    label="Cantidad"
                    class="opacity-false"
                    disabled
                />
            </div>

            <div class="col-12 mt-5 d-flex align-items-end">
                <input-money-component
                    v-model="costTemp"
                    border
                    :enabled="enabledUpdate"
                    :label="
                        isInputCostUnit ? 'Costo de unidad' : 'Costo de lote'
                    "
                    :message-error="
                        !isValidCost & introValidators.costNew
                            ? 'Costo No puede ser $0'
                            : ''
                    "
                    :disabled="!enabledUpdate"
                    class="w-100 mw-100"
                    @blur="validateCost"
                />
                <vs-tooltip>
                    <vs-button
                        icon
                        border
                        :active="isInputCostUnit"
                        @click="updateUnitCostSw"
                    >
                        {{ isInputCostUnit ? "1️⃣" : "🔢" }}
                    </vs-button>
                    <template #tooltip>
                        <div class="content-tooltip">
                            <span v-if="isInputCostUnit">
                                Registrar con costo de lote
                            </span>
                            <span v-else>Registrar con costo de unidad</span>
                        </div>
                    </template>
                </vs-tooltip>
            </div>

            <div class="row h-10 mt-3">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button @click="updateInputsInventoryByStoreComponent">
                        Actualizar entrada de inventario
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "InventoryInputDetail",
    components: { InputMoneyComponent },
    data: () => ({
        loaderInstance: null,
        enabledUpdate: false,
        isLoading: false,
        itemComponent: {
            productId: 1,
            referenceId: 1,
            name: "Nombre de producto",
            size: "",
            color: "",
            id: 0,
            createdAt: "",
            costNew: 0,
            quantity: 1,
            merchantId: "",
            merchantType: "store",
            merchantName: "",
            priceOffer: 0
        },
        introValidators: { costNew: false },
        costTemp: 0,
        id: null,
        revenue: 0,
        isInputCostUnit: true,
        messageCost: "Costo de lote",
        messageValue: 0
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidCost() {
            return this.itemComponent.costNew > 0;
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("inventory:Get", "/app/inventories")))
            return;
        this.id = parseInt(this.$route.params.id);
        this.getBaseInputInventory({
            id: this.id,
            merchantId: this.$route.query.merchantId,
            merchantType: this.$route.query.merchantType
        });
    },
    methods: {
        ...mapActions("inventories", [
            "getInputInventoryById",
            "updateInputsInventoryByStore"
        ]),
        async getBaseInputInventory({ id, merchantId, merchantType }) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                this.itemComponent = await this.getInputInventoryById({
                    id,
                    merchantId,
                    merchantType
                });
                this.isInputCostUnit = true;
                this.costTemp = +this.itemComponent.costNew;
                this.isInputCostUnit = true;
                this.calculateUnitCost();
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/inventories");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateInputsInventoryByStoreComponent() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Inventario invalido",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                await this.updateInputsInventoryByStore(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        validateCost() {
            this.introValidators.costNew = true;
            this.calculateUnitCost();
        },
        updateUnitCostSw() {
            if (this.isInputCostUnit) {
                this.costTemp = this.costTemp * this.itemComponent.quantity;
            } else {
                this.costTemp = this.costTemp / this.itemComponent.quantity;
            }
            this.isInputCostUnit = !this.isInputCostUnit;
            this.calculateUnitCost();
        },
        calculateUnitCost() {
            let costUnit = 0;
            if (this.isInputCostUnit) {
                costUnit = +this.costTemp;
                this.itemComponent.costNew = parseInt(this.costTemp);
                this.messageCost = "Costo de lote";
                this.messageValue = this.$options.filters.money(
                    costUnit * this.itemComponent.quantity
                );
            } else {
                costUnit = this.costTemp / this.itemComponent.quantity;
                this.itemComponent.costNew = +costUnit;
                this.messageCost = `Costo de unidad`;
                this.messageValue = this.$options.filters.money(
                    costUnit.toFixed(2)
                );
            }
            this.revenue = 1 - costUnit / this.itemComponent.priceOffer;
        },
        validateData() {
            this.introValidators = {
                costNew: true
            };

            return this.isValidCost;
        }
    }
};
</script>
